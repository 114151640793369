.playlist-section {
  background-color: white;
  border-radius: 32px;
  width: 100%;
  max-width: 1200px;
  margin: 0 2.5vh;
}
.playlist-section .playlist-section-container {
  height: auto;
  width: 100%;
  padding: 20px 10px;
  background-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.playlist-section .playlist-section-container h1 {
  margin-bottom: 2rem;
  margin-left: 0;
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  color: white;
  font-family: "Chakra Petch", sans-serif;
}
.playlist-section .playlist-section-container h2 {
  color: white;
  font-weight: 400;
  font-size: 1.125rem;
  margin-top: 10px;
}
.playlist-section .playlist-section-container iframe {
  width: 960px;
  height: 540px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.playlist-section .playlist-section-container .video-card img {
  border-radius: 5px;
}
.playlist-section .playlist-section-container .video-card {
  cursor: pointer;
}
.playlist-section .playlist-section-container .vid-gallery {
  margin-top: 25px;
}


.playlist-section .swiper-slide.video-card {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}
.playlist-section .swiper-slide.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.playlist-section .swiper {
  width: 100%;
  height: 100%;
}
.playlist-section .append-buttons {
  text-align: center;
  margin-top: 20px;
}

.playlist-section .append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.swiper-pagination {
  position: relative;
  margin-top: 50px;
}


.swiper-pagination-bullet {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

@media only screen and (max-width: 768px) {
  .playlist-section .swiper-slide.video-card {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .playlist-section .playlist-section-container h2 {
    font-size: 0.875rem;
  }
  .playlist-section {
    background-color: #ffffff;
    border-radius: 0;
    width: 100vw;
    margin: 0;
  }
  .playlist-section .playlist-section-container iframe {
    width: 90vw;
    height: 400px;
  }
  .playlist-section .playlist-section-container h1 {
    font-size: 2.25rem;
    margin-left: 0;
  }
  .playlist-section .post-list .swiper::before {
    background: none;
  }
  .playlist-section .post-list .swiper::after {
    background: none;
  }
}/*# sourceMappingURL=ChildVidSlider.css.map */