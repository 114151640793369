.success-noti {
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation-name: sliderAppear;
    animation-duration: 0.25s;
    animation-timing-function: ease-in;
  }

  .success-noti-layer {
    z-index: 1;
    position: absolute;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .success-noti-center {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: 250px;
    background-color:  rgba(0, 0, 0, 0.9);
    z-index: 2;
    border-radius: 10px;
  }
  
  .success-noti-center p {
    color: #ffdc73;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
  }

  .btnClose {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  #close {
    width: 30px;
    opacity: 0.8;
  }