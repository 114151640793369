body {
    overflow: visible;
}

html {
    overflow: visible;
}

/*Scroll bar nav*/
::-webkit-scrollbar {
    width: 10px;
    color: transparent;
}
  
  /* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(155, 155, 155);
    border-radius: 10px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "Open Sans", sans-serif;
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: "Open Sans", sans-serif;
  }
  
::-ms-input-placeholder { /* Microsoft Edge */
    font-family: "Open Sans", sans-serif;
  }